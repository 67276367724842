import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade' // see https://www.react-reveal.com/examples/

import Layout from '../components/Layout'

const Wrapper = styled.div`
background-color:#f2f2f2;
font-family: 'PT Sans Caption', sans-serif;
	display: flex;
	align-items: center;
	flex-direction: column;
  text-align: center;
  padding: 1.5rem;
  animation: hide;
  width:100%;
  
`

const Wrapper2 = styled.div`

font-family: 'PT Sans Caption', sans-serif;
	display: flex;
	align-items: center;
	flex-direction: column;
  text-align: center;
  padding: 1rem;
  animation: hide;
  width:100%;
  
`

const Header = styled.h1`
margin-top: 0;
  color: #ffff;
`

const Subheader = styled.h2`
color: #ffff;
margin:0;
  font-size: 2rem;
`

const Text = styled.p`
color: #122A59;
font-weight: 700;
font-family: 'Vera Humana 95 Bold';
font-size:20px;
`

const Button = styled.a.attrs({
  className: 'w-100 tc', 
  target: '_blank'
})`
  font-weight: bold;
  text-decoration: none;
  color: #F2F2F2;
  padding: 0.5rem 0;
  background-color: #122A59;
  border-radius: 4px;
  border: none;
  margin:100px;


`
const Logo2 = styled.img.attrs({
  alt: 'logo2'
})`
  max-width: 100%;
  max-height: 50%;
`



const SimpleStarter11 = ({ data: { markdownRemark: { frontmatter } } }) =>
  <Layout frontmatter={{...frontmatter, backgroundColor: '#f2f2f2'}}>
    <Wrapper>
    <Fade bottom ssrReveal>
      <div>
        <Wrapper2>
      <Header><Logo2 src={frontmatter.logo.publicURL} /></Header>
        </Wrapper2>
        <Subheader>
        
        
        </Subheader>
        <Text>{frontmatter.text}</Text>
        <section className='w-100 flex-column flex justify-center items-center'>
        <Button target='_blank' href='https://www.zreynalaw.com/contact-us' color={frontmatter.primaryColor}>Learn More</Button>          </section>
      </div>
    </Fade>
    </Wrapper>
  </Layout>

export default SimpleStarter11

export const pageQuery = graphql`
  query SimpleStarter11BySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        templateKey
        title
        description
        header
        subheader
        text
        buttonText
        link
        logo {
          publicURL
        }

        
        
        favicon {
          publicURL
        }
      }
    }
  }
`
